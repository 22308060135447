// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

//global.toastr = require("toastr")
global.toastr = require('./web/toastr.js');

import 'cookieconsent/build/cookieconsent.min';

import './web/owl.carousel';
//import './web/toastr.js'
import './web/scripts';
import Locale from './web/locale';

import ContactForm from './web/contact-form';
import Cookies from './web/cookies';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener('turbolinks:load', function () {
    ContactForm.init();
    Locale.init();
    Cookies.init();
});
