/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Zheko Hristov on 12/11/2021
 *
 * Cookies scripts
 **/

var cookies = (function ($) {
    function init() {
        askCookiesPermission();
    }

    function askCookiesPermission() {
        window.cookieconsent.initialise({
            palette: {
                popup: {
                    background: '#0b428f',
                    text: '#fefefe',
                },
                button: {
                    background: '#838386',
                    text: '#fefefe',
                },
            },
            theme: 'classic',
            content: {
                message:
                    'This website uses cookies to improve user experience, functionality and performance. If you continue browsing the site, you consent to the use of cookies on this website. Note that you can change your browser/cookie settings at any time. To learn more about the use of cookies and how to adjust your ',
                link: 'Learn more',
                href: 'https://varna-coworking.space/en-US/Cookies',
            },
        });
    }

    return {
        init: init,
    };
})(jQuery);

export default cookies;
