/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Zheko Hristov on 30/11/2021
 *
 * Main scripts
 **/

$(document).on('turbolinks:before-render', function () {
    (function () {
        'use strict';

        // fix issue for showing the background color and after the images load show them
        $('.image--lazy-load, .image__container--lazy-load').addClass('not-yet-loaded');

        // fix issue with browser back button and slider
        $('#testimonials-slider').owlCarousel('destroy');

        // close the mobile menu if it is not closed after browser back button clicks
        if ($('.not-on-top .header__container--bottom').hasClass('showed')) {
            $('.not-on-top .header__container--bottom').css({
                transition: 'background 0.4s, transform 0.4s 0.3s',
                '-webkit-transition': 'background 0.4s, -webkit-transform 0.4s 0.3s',
            });
            $('.header__container--bottom').removeClass('showed');
        }

        // fix issue with back button - hide services submenu
        $('.services-submenu__link--show').removeClass('submenu-showed');
        $('body').removeClass('services-submenu-showed');

        // fix issue with back button - remove toast messages container
        $('#toast-container').remove();
    })();
});

$(document).on('turbolinks:load', function () {
    // get the padding top value of the main container element, used in topMenu function
    var mainContainerPaddingTopValue = parseFloat($('.main__container--outer').css('padding-top'));
    topMenu();

    calculateMainContainerOuterMinHeight();
    calculatePortfolioDynamicImageContainer();
    calculateDimensionsSingleProjectContainer();
    calculateTopPositionDynamicProjectImage();
    serviceTitleCutText();
    serviceShortDescriptionCutText();
    intializeContactFormEvents();
    mobileHover();
    calculateMobileMenuContainerHeight();

    // if the devices are iPad|iPhone|iPod, execute the function only for them,
    // because this function will stop the default zooming on Androd devices
    if (checkIfIsIOS()) {
        addMaximumScaleToMetaViewport();
    }

    // lazy loading images/background images

    // get all divs with background-image property
    var lazyLoadBackgroundImages = $('.image__container--lazy-load');
    // get all images
    var lazyLoadImages = $('.image--lazy-load');

    // check if browser supports intersection observer
    // else load images on load
    if ('IntersectionObserver' in window) {
        var lazyLoadingImagesObserver = new IntersectionObserver(
            function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        if ($(entry.target).hasClass('image--lazy-load')) {
                            // if the entry is image
                            $(entry.target).attr('src', $(entry.target).attr('data-src'));
                            $(entry.target).removeClass('not-yet-loaded');
                        } else if ($(entry.target).hasClass('image__container--lazy-load')) {
                            // if the entry is div with background-image property
                            $(entry.target).removeClass('not-yet-loaded');
                        }

                        // unobserve the entry
                        lazyLoadingImagesObserver.unobserve(entry.target);
                    }
                });
            },
            {
                // start observing at earlier point
                rootMargin: '0px 0px 500px 0px',
            }
        );

        // iterate each div with background-image property and observe it
        lazyLoadBackgroundImages.each(function () {
            // get the js object from the jquery object
            var currentLazyBackgroundImage = $(this)[0];
            lazyLoadingImagesObserver.observe(currentLazyBackgroundImage);
        });

        // iterate each image and observe it
        lazyLoadImages.each(function () {
            // get the js object from the jquery object
            var currentLazyImage = $(this)[0];
            lazyLoadingImagesObserver.observe(currentLazyImage);
        });
    } else {
        // iterate each div with background-image property and remove lazy loading class for showing on load
        lazyLoadBackgroundImages.each(function () {
            $(this).removeClass('not-yet-loaded');
        });

        // iterate each image and set the src attribute on load
        lazyLoadImages.each(function () {
            $(this).attr('src', $(this).attr('data-src'));
            $(this).removeClass('not-yet-loaded');
        });
    }

    // function for calculating .main__container--outer element min-height
    function calculateMainContainerOuterMinHeight() {
        var windowHeight = $(window).height();
        // outerHeight(true), because we want also and the margin
        var footerHeightAndMargin = $('footer').outerHeight(true);
        var calculatedMainContainerMinHeightValue = windowHeight - footerHeightAndMargin;

        // innerHeight, because it includes also the padding
        $('.main__container--outer').css({ 'min-height': calculatedMainContainerMinHeightValue });
    }

    // function for changing header styles
    function topMenu() {
        if (window.innerWidth > 960) {
            if ($(this).scrollTop() >= mainContainerPaddingTopValue) {
                $('header').addClass('not-on-top');
            } else {
                $('header').removeClass('not-on-top');
            }
        } else {
            $('header').addClass('not-on-top');
        }
    }

    // show/hide contacts page map image on en and on bg languages
    if ($('.contacts-image__text--header').length > 0) {
        if (window.innerWidth > 960) {
            $('.header__container--languages a').each(function () {
                if (
                    $(this).text().trim().toLocaleLowerCase() === 'bg' &&
                    $(this).hasClass('active-culture-link')
                ) {
                    $('.image--map-offices').css({ display: 'none' });
                    $('.image--bg-map').css({ display: 'block' });
                }
            });
        } else {
            $('.header__container--languages-mobile a').each(function () {
                if (
                    $(this).text().trim().toLocaleLowerCase() === 'bg' &&
                    $(this).hasClass('active-culture-link')
                ) {
                    $('.image--map-offices').css({ display: 'none' });
                    $('.image--bg-map').css({ display: 'block' });
                }
            });
        }
    }

    // disable/enable submit button for the contact form
    $('#checkbox--agree').on('change', function () {
        if ($(this).is(':checked')) {
            $('.container--button-submit button').removeClass('disabled');
            $('.container--button-submit button').removeAttr('disabled');
        } else {
            $('.container--button-submit button').addClass('disabled');
            $('.container--button-submit button').prop('disabled', true);
        }
    });

    // show mobile menu
    $('.header__container--outer .fa-bars').on('click', function () {
        $('.not-on-top .header__container--bottom').css({
            transition: 'transform 0.4s, background 0.4s 0.3s',
            '-webkit-transition': '-webkit-transform 0.4s, background 0.4s 0.3s',
        });
        $('.header__container--bottom').addClass('showed');
    });

    // hide mobile menu
    $('.header__container--bottom .bottom__container--inner .fa-times').on('click', function () {
        $('.not-on-top .header__container--bottom').css({
            transition: 'background 0.4s, transform 0.4s 0.3s',
            '-webkit-transition': 'background 0.4s, -webkit-transform 0.4s 0.3s',
        });
        $('.header__container--bottom').removeClass('showed');
    });

    // toastr messages functions
    var validationSummary = $('.validation-summary-errors');

    if (validationSummary.length > 0) {
        validationSummary.find('li').each(function (i) {
            toastr.options.closeButton = true;
            toastr.error($(this).text());
        });
    }

    var messageSuccess = $('.message__text--success');
    if (messageSuccess.length > 0) {
        toastr.options.closeButton = true;
        toastr.success(messageSuccess.text());
    }

    var messageError = $('.message__text--error');

    if (messageError.length > 0) {
        toastr.options.closeButton = true;
        toastr.error(messageError.text());
    }

    // scroll to contact form if there are errors
    if ($('.field-validation-error').length > 0) {
        setTimeout(function () {
            $('html, body').animate(
                {
                    scrollTop: $('#target-form').offset().top - 100,
                },
                2000
            );
        }, 1000);
    }

    // owl-carousel tetsimonials slider
    $('#testimonials-slider').owlCarousel({
        loop: $('.testimonials__container--single').length > 1 ? true : false,
        nav: true,
        dots: false,
        touchDrag: false,
        mouseDrag: false,
        responsive: {
            0: {
                items: 1,
            },
            500: {
                items: 2,
            },
            800: {
                items: 3,
            },
        },
    });

    // show/hide testimonials full image and change src attribute from data-src of the image
    $('#testimonials-slider').on(
        'click',
        '.testimonials-description__container--outer',
        function () {
            const thisTestimonialDescriptionContainer = $(this);
            if (
                thisTestimonialDescriptionContainer.closest('.testimonials__container--single')
                    .length
            ) {
                const thisTestimonialDescriptionText = thisTestimonialDescriptionContainer
                    .find('.testimonials__text--description')
                    .text()
                    .trim();
                $(
                    '.testimonials__container--full-description .testimonials__text--description'
                ).text(thisTestimonialDescriptionText);
                $('.testimonials__container--full-description').addClass('showed');
                setTimeout(function () {
                    $('.testimonials__container--full-description').css({
                        'transition-delay': '0.3s',
                    });
                    $(
                        '.testimonials__container--full-description .testimonials-description__container--outer'
                    ).css({ 'transition-delay': '0s' });
                }, 100);
            }
        }
    );

    $('.testimonials__container--full-description').on('click', closeTestimonialFullDescription);

    $(document).on('keydown', function (e) {
        var pressedKey = e.key || e.keyCode;
        if (pressedKey === 'Escape' || pressedKey === 'Esc' || pressedKey === 27) {
            closeTestimonialFullDescription();
        }
    });

    function closeTestimonialFullDescription() {
        $('.testimonials__container--full-description').removeClass('showed');
        setTimeout(function () {
            $('.testimonials__container--full-description').css({ 'transition-delay': '0s' });
            $(
                '.testimonials__container--full-description .testimonials-description__container--outer'
            ).css({ 'transition-delay': '0.3s' });
        }, 100);
    }

    // check if we have some empty elements(paragraphs) come from the HTML editor from the CMS part and remove it
    $('.other-pages__container--description *').each(function () {
        var thisElement = $(this);
        // xx/06/2021 - for first time image is added trough the cms editor and this check removes also the images
        // therefore, update it to check, if the element has children of type image or it is image
        // and if it does not satisfy the conditions, it is empty paragraph, which has to be removed
        if (thisElement.find('img').length === 0 && !thisElement.is('img')) {
            if (thisElement.text().trim().length < 1) {
                thisElement.remove();
            }
        }
    });

    // remove the bottom margin from the last element
    $('.other-pages__container--description *').last().css({ 'margin-bottom': '0' });
    $('.other-pages__container--description > *').last().css({ 'margin-bottom': '0' });

    // portfolio by keyword page - when click on keyword redirect to portfolio index page
    $('.all-projects__container--keyword > span').on('click', function () {
        $('.header__container--bottom li a.active')[0].click();
    });

    // project details page check if similar projects contains the current project and remove
    // remove the section if there are no similar projects
    if ($('.project-details__container--similar').length > 0) {
        var currentProjectId = parseInt(
            $('.other-pages__container--outer').attr('data-project-id')
        );
        $('.all-projects__container--single.similar-project').each(function () {
            var thisSimilarProject = $(this);
            var thisSimilarProjectId = parseInt(thisSimilarProject.attr('data-similar-project-id'));
            if (thisSimilarProjectId == currentProjectId) {
                thisSimilarProject.remove();
            }
        });

        if ($('.all-projects__container--single.similar-project').length < 1) {
            $('.project-details__container--similar').remove();
        }
    }

    // contacts page show details for selected store
    $('.contacts__container--offices-titles').on('click', '.text--office-title', function () {
        const thisOfficeContainer = $(this);
        const thisOfficeIndex = thisOfficeContainer.index();
        $('.text--office-title').removeClass('selected');
        thisOfficeContainer.addClass('selected');
        $('.contacts-office__container--single').removeClass('selected');
        $(
            '.contacts-office__container--single:nth-of-type(' + (thisOfficeIndex + 1) + ')'
        ).addClass('selected');
    });

    // show services submenu
    $('.services-submenu__link--show').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('submenu-showed');
        if ($(this).hasClass('submenu-showed')) {
            $('body').addClass('services-submenu-showed');
            servicesSubmenuContainersDimensionsCalc();
        } else {
            $('body').removeClass('services-submenu-showed');
        }
    });

    // fix iOs devices hover not working
    function mobileHover() {
        $('*')
            .on('touchstart', function () {
                $(this).trigger('hover');
            })
            .on('touchend', function () {
                $(this).trigger('hover');
            });
    }

    // function for calculating top position of dynamic project image in project details page top section
    function calculateTopPositionDynamicProjectImage() {
        if ($('.portfolio__container--dynamic-image-outer').length > 0) {
            if (window.innerWidth <= 1120) {
                setTimeout(function () {
                    var parentContainerPaddingTop = parseFloat(
                        $('.top__container--image').css('padding-top')
                    );
                    var siblingHeaderElementHeight = parseFloat(
                        $('.top-image__text--header').height()
                    );

                    var calculatedPositionTop =
                        parentContainerPaddingTop + siblingHeaderElementHeight;

                    $('.portfolio__container--dynamic-image-outer').css({
                        top: calculatedPositionTop,
                    });
                }, 100);
            } else {
                $('.portfolio__container--dynamic-image-outer').css({ top: 'auto' });
            }
        }
    }

    // function for calculating dimensions of the single project container in projects index page
    function calculateDimensionsSingleProjectContainer() {
        if ($('.all-projects__container--single').length > 0) {
            setTimeout(function () {
                var elementInfoContainerHeight = parseFloat(
                    $('.all-projects__container--single-info').height()
                );
                var elementInfoContainerTitleMarginBottom = parseFloat(
                    $('.all-projects__container--single-title').css('margin-bottom')
                );
                var elementDescriptionLineHeight = parseFloat(
                    $('.all-projects__container--single-description').css('line-height')
                );

                $('.all-projects__container--single-info').each(function () {
                    var thisElementInfoProject = $(this);
                    var thisElementInfoProjectTitleHeight = parseFloat(
                        thisElementInfoProject
                            .find('.all-projects__container--single-title')
                            .outerHeight(true)
                    );
                    var thisElementInfoProjectKeywordsHeight = parseFloat(
                        thisElementInfoProject
                            .find('.all-projects__container--single-keywords')
                            .outerHeight()
                    );

                    var calculatedInfoDescriptionContainerHeight =
                        elementInfoContainerHeight -
                        thisElementInfoProjectTitleHeight -
                        thisElementInfoProjectKeywordsHeight -
                        elementInfoContainerTitleMarginBottom;
                    var rowsCount = Math.floor(
                        calculatedInfoDescriptionContainerHeight / elementDescriptionLineHeight
                    );

                    var calculatedDescriptionContainerHeight =
                        rowsCount * elementDescriptionLineHeight;

                    if (calculatedDescriptionContainerHeight <= 0) {
                        calculatedDescriptionContainerHeight = 0;
                    }

                    // set the calculated max-width property to each project description container
                    thisElementInfoProject
                        .find('.all-projects__container--single-description')
                        .css('max-height', calculatedDescriptionContainerHeight);

                    // cut the description text for each project description container, based on the above calculated dimensions
                    // and add ellipssis at the end of the last line
                    ellipsizeTextBox(
                        thisElementInfoProject.find('.all-projects__container--single-description')
                    );
                    // cut the title text for each project title containerand add ellipssis at the end of the last line
                    ellipsizeTextBox(
                        thisElementInfoProject.find('.all-projects__container--single-title')
                    );

                    // calculate and cut keywoard container for each project keywords container
                    // and add ellipssis at the end
                    calculatePortfolioSingleProjectKeywords(
                        thisElementInfoProject.find('.all-projects__container--single-keywords')
                    );
                });
            }, 100);
        }
    }

    // function for calculating container with dynamic image for the project details page top section
    function calculatePortfolioDynamicImageContainer() {
        // variables for ratio between portfolio top section static image and portfolio top section dynamic image
        var constRatioWidth = 1.41088742810189;
        var constRatioHeight = 1.3311421528348397;

        var portfolioHeaderStaticImageWidth = $('.image--portfolio-static').width();
        var portfolioHeaderStaticImageHeight = $('.image--portfolio-static').height();

        $('.portfolio__container--dynamic-image-outer').width(portfolioHeaderStaticImageWidth);
        $('.portfolio__container--dynamic-image-outer').height(portfolioHeaderStaticImageHeight);

        $('.project__container--dynamic-image').width(
            portfolioHeaderStaticImageWidth / constRatioWidth
        );
        $('.project__container--dynamic-image').height(
            portfolioHeaderStaticImageHeight / constRatioHeight
        );
    }

    // function for calculating keywords in all projects page - single project container(.all-projects__container--single)
    function calculatePortfolioSingleProjectKeywords(keyWordsContainerOuter) {
        var thisKeywordsContainer = keyWordsContainerOuter;
        // display all keyword containers - when resize it has to show all and after that execute the calculations for hidding
        thisKeywordsContainer
            .find($('.all-projects__link--keyword').not('.dots'))
            .css({ display: 'inline-block' });
        // get height of keywords container - use Math.round function, because .prop('scrollHeight') value is always rounded
        var thisKeywoordsContainerVisibleHeight = Math.round(thisKeywordsContainer.height());
        // get full height of keywords container
        var thisKeywordsContainerFullHeight = thisKeywordsContainer.prop('scrollHeight');
        // if the height is lower then the full height -> we have more keyword containers and has to show the dots container
        // else if the dots container is showed, remove it - when resize
        if (thisKeywoordsContainerVisibleHeight < thisKeywordsContainerFullHeight) {
            // append the dots container at the end
            if (thisKeywordsContainer.find('.all-projects__link--keyword.dots').length < 1) {
                thisKeywordsContainer.append(
                    '<a class="all-projects__link--keyword dots"> ... </a>'
                );
            }
        } else {
            if (thisKeywordsContainer.find('.all-projects__link--keyword.dots').length > 0) {
                thisKeywordsContainer.find('.all-projects__link--keyword.dots').remove();
            }
        }

        // go trough keyword links containers and if the visible height is lower then the full height hide
        // keyword containers without new added dots container
        $(thisKeywordsContainer.find('.all-projects__link--keyword').get().reverse()).each(
            function () {
                var thisKeywordLink = $(this);
                // the last check ((thisKeywordsContainerFullHeight - thisKeywoordsContainerVisibleHeight) > 1) is added after
                // strange browser behavior - the height is for example 32rem, but in console it shows 31.9rem - fix
                if (
                    thisKeywoordsContainerVisibleHeight < thisKeywordsContainerFullHeight &&
                    thisKeywordsContainerFullHeight - thisKeywoordsContainerVisibleHeight > 1
                ) {
                    thisKeywordLink.not('.dots').css({ display: 'none' });
                    // uptdate variable for full height container with the new value after removing elements
                    thisKeywordsContainerFullHeight = thisKeywordsContainer.prop('scrollHeight');
                }
            }
        );
    }

    // function for calculating and cutting text on the service short description in all services page,
    // based on the single service container dimensions and adding dots at the end of the last line
    function serviceShortDescriptionCutText() {
        if ($('.all-services__container--description').length > 0) {
            $('.all-services__container--description').each(function () {
                var thisServiceDescriptionEl = $(this);
                ellipsizeTextBox(thisServiceDescriptionEl);
            });
        }
    }

    // function for calculating and cutting text on the service title in all services page,
    // based on the single service container dimensions and adding dots at the end of the last line
    function serviceTitleCutText() {
        if ($('.all-services__text--title').length > 0) {
            $('.all-services__text--title').each(function () {
                var thisServiceTitleEl = $(this).find('span');
                ellipsizeTextBox(thisServiceTitleEl);
            });
        }
    }

    // function for calculating and cutting text on the current element, based on the element dimensions
    // and adding dots at the end of the last line
    function ellipsizeTextBox(element) {
        // get the initial element text and set it as attribute - this is used on resize,
        // show full text and after that execute the calculations, based on the new dimensions of the element
        if (!element.hasClass('initial-end')) {
            element.attr('data-initial-text', element.text().trim());
            element.addClass('initial-end');
        }

        // when execute the function after resize update the cutted text with the initial one from the attribute
        if (element.text() != element.attr('data-initial-text')) {
            element.text(element.attr('data-initial-text'));
        }

        // round the visible height, because .prop('scrollHeight') is rounded
        var elementVisibleHeight = Math.round(element.height());
        var elementFullHeight = element.prop('scrollHeight');

        // split the words in to array, which will be manipulated later
        var wordArray = element.text().trim().split(' ');

        // check if the visible height of the element is > 0, because of the dynamic calculation of the height,
        // based on other elements in the single project container
        // - at some point the height value is 0 and there is no need fo further calculations to execute
        if (elementVisibleHeight > 0) {
            // while the visible height is smaler then the full height remove the last item in the array and update elementFullHeight with the new full height value
            // the last check ((elementFullHeight - elementVisibleHeight) > 1) is added after
            // strange browser behavior - the height is for example 32rem, but in console it shows 31.9rem - fix
            while (
                elementVisibleHeight < elementFullHeight &&
                elementFullHeight - elementVisibleHeight > 1
            ) {
                // remove the last array item
                wordArray.pop();
                // convert array elements in to string and append them to the element
                element.html(wordArray.join(' ') + '...');
                // update variable for full height container with the new value after removing elements
                elementFullHeight = element.prop('scrollHeight');
            }
        }
    }

    // go to previous page from item details page
    $('.details__container--back-button').on('click', function () {
        window.history.back();
    });

    // adding bot protection function
    function intializeContactFormEvents() {
        $('.js-contact-select-department').val('support');
    }

    // services submenu container dimensions calculate
    function servicesSubmenuContainersDimensionsCalc() {
        // reset max-height property for calculations on resize
        $('.services-submenu__container--services').css({ height: 'auto' });

        // calculate the outer submenu container height
        $('.header__container--services-submenu').css({
            'max-height': $(window).height() - $('header').height(),
        });

        // calculate each services-submenu__container--services max-height
        $('.services-submenu__container--single-category').each(function () {
            const thisCategoryContainer = $(this);
            const thisCategoryNameContainer = thisCategoryContainer.find(
                '.services-submenu__container--category-name'
            );
            const thisCategoryServicesContainer = thisCategoryContainer.find(
                '.services-submenu__container--services'
            );

            const thisCategoryContainerHeight = thisCategoryContainer.height();
            const thisCategoryNameContainerFullHeight = thisCategoryNameContainer.outerHeight(true);

            thisCategoryServicesContainer.height(
                thisCategoryContainerHeight - thisCategoryNameContainerFullHeight
            );
        });
    }

    // function fo setting maximum-scale=1.0 to meta viewport
    // prevent iOS input zoom and not pevent the default zooming for Android
    function addMaximumScaleToMetaViewport() {
        const el = document.querySelector('meta[name=viewport]');
        if (el !== null) {
            let content = el.getAttribute('content');
            let re = /maximum\-scale=[0-9\.]+/g;
            if (re.test(content)) {
                content = content.replace(re, 'maximum-scale=1.0');
            } else {
                content = [content, 'maximum-scale=1.0'].join(', ');
            }
            el.setAttribute('content', content);
        }
    }

    // funcion for check the device
    function checkIfIsIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    // functiom for calculating the height of mobile menu container
    function calculateMobileMenuContainerHeight() {
        if (window.innerWidth < 961) {
            $('.bottom__container--inner').innerHeight(window.innerHeight);
        } else {
            $('.bottom__container--inner').css({ height: 'auto' });
        }
    }

    // scroll function
    $(window).on('scroll', function () {
        // when scroll change styles for the header
        topMenu();
    });

    // windowWidth variable
    var windowWidth;
    windowWidth = $(window).width();

    // resize function
    $(window).on('resize', function () {
        // fix mobile, triggering resize when address bar shows and hides
        if (windowWidth != $(window).width()) {
            windowWidth = $(window).width();

            // update .header__container--bottom element transition property on resize, because of the differences
            // for the desktop and mobile version of visual showing and hiding the element
            if (window.innerWidth > 960) {
                $('.header__container--bottom').css({
                    transition: 'none',
                    '-webkit-transition': 'none',
                });
            } else {
                if (!$('.not-on-top .header__container--bottom').hasClass('showed')) {
                    $('.not-on-top .header__container--bottom').css({
                        transition: 'none',
                        '-webkit-transition': 'none',
                    });
                }
            }

            // get the padding top value of the main container element, used in topMenu function
            mainContainerPaddingTopValue = parseFloat(
                $('.main__container--outer').css('padding-top')
            );
            topMenu();

            calculateMainContainerOuterMinHeight();
            serviceTitleCutText();
            serviceShortDescriptionCutText();
            calculateTopPositionDynamicProjectImage();
            calculatePortfolioDynamicImageContainer();
            calculateDimensionsSingleProjectContainer();
            servicesSubmenuContainersDimensionsCalc();
        }

        // calculate also on mobile devices after the address bar is shown or hidden
        calculateMobileMenuContainerHeight();
    });
});
